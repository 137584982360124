import React, { useEffect, useState } from "react";
import { Calendar as ShadcnCalendar } from "../../@/components/calendar";
import { Button } from "../../@/components/button";
import { ShoppingCart, FileUp } from "lucide-react";

const Calendar = ({
  setSelectedDate,
  datesOfReceiptsUploaded,
  datesOfReceipts,
}) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    handleDateChange(date);
  }, []);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setSelectedDate(formatDate(newDate));
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const handleTodayClick = () => {
    const today = new Date();
    handleDateChange(today);
  };

  const hasReceipt = (date) => {
    const formattedDate = formatDate(date);
    return datesOfReceipts?.some((d) => d && d.startsWith(formattedDate));
  };

  const hasUploadedReceipt = (date) => {
    const formattedDate = formatDate(date);
    return datesOfReceiptsUploaded?.some(
      (d) => d && d.startsWith(formattedDate)
    );
  };

  return (
    <div className="mt-4 w-full max-w-6xl  mx-auto sm:p-4 py-4 sm:py-8 bg-white border rounded-lg gap-4 shadow-md grid grid-cols-1 md:grid-cols-2">
      <div className="px-4  grid place-items-center">
        <h1 className="text-2xl font-bold text-amber-500">Receipts Calendar</h1>
        <div className="flex flex-col items-center">
          <ul className="  list-inside text-sm text-gray-500 hidden md:block">
            <li className="">
              - Click on a date to see the receipts for that day.{" "}
            </li>
            <li className="">
              - Click on a receipt to see the full details/analysis.{" "}
            </li>
          </ul>
          <p className="mt-8 text-sm text-gray-500">
            Dates with the{" "}
            <span className="text-grey">
              {" "}
              <FileUp className="h-4 w-4 mx-1 inline text-grey mr-1" />{" "}
            </span>
            icon are on which receipts have been uploaded.
          </p>
          <div className="mt-4 flex justify-center">
            <Button onClick={handleTodayClick} className="px-6 py-2">
              Click here to see today's receipts !
            </Button>
          </div>{" "}
        </div>
      </div>
      <ShadcnCalendar
        mode="single"
        selected={date}
        onSelect={handleDateChange}
        className="w-full mx-auto bg-gray-50 rounded-xl"
        modifiers={{
          hasReceipt: (date) => hasReceipt(date),
          hasUploadedReceipt: (date) => hasUploadedReceipt(date),
        }}
        modifiersStyles={{
          hasReceipt: { border: "2px solid orange" },
          hasUploadedReceipt: { backgroundColor: "lightgrey" },
        }}
        components={{
          DayContent: ({ date, isSelected }) => (
            <div
              className={`relative w-full h-full flex items-center justify-center ${isSelected ? "text-white" : ""}`}
            >
              {date.getDate()}
              <div className="absolute top-0 right-0 flex">
                {hasReceipt(date) && (
                  <ShoppingCart className="h-3 w-3 text-orange-500 mr-1" />
                )}
                {hasUploadedReceipt(date) && (
                  <FileUp className="h-3 w-3 text-grey" />
                )}
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default Calendar;