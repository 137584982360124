import React, { useEffect, useState } from "react";
import { Pie, PieChart, Cell, Legend } from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "../../@/components/card";
import { fetchReceiptDetails } from "../../utils/firestoreUtils";

export function DonutChart({ renderedReceiptId }) {
  const [chartData, setChartData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    const fetchReceiptData = async () => {
      const fetchedData = await fetchReceiptDetails(renderedReceiptId, "allUserReceipts");
      processData(fetchedData.processed_data);
    };

    fetchReceiptData();
  }, [renderedReceiptId]);

  const processData = (data) => {
    if (data && data.items) {
      const categoryTotals = {};
      
      data.items.forEach((item) => {
        const category = Array.isArray(item.category) ? item.category[0] : item.category;
        const price = parseFloat(item.total_price);
        
        categoryTotals[category] = (categoryTotals[category] || 0) + price;
      });

      const newChartData = Object.entries(categoryTotals).map(([category, value]) => ({
        category,
        value,
      }));

      setChartData(newChartData);
      setTotalValue(newChartData.reduce((acc, curr) => acc + curr.value, 0));
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

  if (!chartData.length) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="bg-white max-w-3xl w-full">
      <CardHeader className="text-center">
        <CardTitle>Your expenses per category</CardTitle>
      </CardHeader>
      <CardContent className='p-0 sm:p-6 pb-6'>
        <PieChart width={300} height={300} className="mx-auto">
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="category"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            label
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </CardContent>
    </Card>
  );
}