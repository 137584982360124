import { useEffect, useRef, useCallback, useState } from "react";

import { RealtimeClient } from "../lib/realtime-api-beta/index.js";
import { ItemType } from "../lib/realtime-api-beta/dist/lib/client.js";
import { WavRecorder, WavStreamPlayer } from "../lib/wavtools/index.js";
import { instructions } from "../utils/conversation_config.js";
import { WavRenderer } from "../utils/wav_renderer";

import { X, Edit, Zap, Mic, AudioLines } from "lucide-react";
import { Toggle } from "../components/cookWithMe/toggle/Toggle";

import "../components/cookWithMe/cookWithMe.scss";
import { LoadingSpinner } from "../components/common/SkeletonLoading.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  saveConversation,
  uploadAudioToStorage,
} from "../utils/firestoreUtils.js";

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: "client" | "server";
  count?: number;
  event: { [key: string]: any };
}
// Define the type for messageData
type MessageData = {
  role: any;
  text: any;
  timestamp: string;
  audio_url?: string; // Add audio_url as an optional property
};

export default function CookWithMe() {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      apiKey: apiKey,
      dangerouslyAllowAPIKeyInBrowser: true,
    })
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);

  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});

  const { user } = useSelector((state: any) => state.user);
  const [conversationId, setConversationId] = useState("");
  const navigate = useNavigate();
  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();

    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `Hello!`,
      },
    ]);

    client.on("conversation.updated", async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    if (client.getTurnDetectionType() === "server_vad") {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }

      client.on("usage", (usage: any) => {
        console.log("usageayusy", usage);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // Function to extract data and convert it to Firestore format
  const formatConversationForFirestore = async (localState: any) => {
    // Initialize the conversation array
    let conversation = [];

    for (let i = 0; i < localState.length; i++) {
      const item = localState[i];
      const formatted = item.formatted;

      let messageData: MessageData = {
        role: item.role,
        text: formatted.text || formatted.transcript, // If no 'text', fallback to 'transcript'
        timestamp: new Date().toISOString(),
      };

      // If there's an audio file, upload it to Firebase Storage and store its URL
      if (formatted.audio && formatted.file && formatted.file.blob) {
        let audioUrl;
        if (
          messageData.text ===
            "Hi there! How can I assist you in the kitchen today?" ||
          messageData.text ===
            "Hi there! How can I help you in the kitchen today?"
        ) {
          audioUrl =
            "https://firebasestorage.googleapis.com/v0/b/mandi-3e35b.appspot.com/o/audio%2Fundefined?alt=media&token=0e339ae8-a8fb-4f5d-b4bb-cbe607af12b3";
        } else {
          audioUrl = await uploadAudioToStorage(formatted.file.blob);
        }
        messageData.audio_url = audioUrl; // Save audio URL in the message data
      }

      // Push the formatted message data to the conversation array
      conversation.push(messageData);
    }

    return conversation; // This array will be used to save the conversation in Firestore
  };

  useEffect(() => {
    if (!user?.betaTester) {
      navigate("/dashboard");
    }
  }, [user]);
  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    // Capture the current state of items
    const itemsToUpdate = [...items]; // Use spread operator to create a copy of the items array
    console.log("itemsToUpdate", itemsToUpdate);

    await formatConversationForFirestore(itemsToUpdate).then((conversation) => {
      // Save the conversation to Firestore
      if (itemsToUpdate.length > 0) {
        const talkId = saveConversation(conversation, conversationId);
        if (talkId) {
          talkId.then((resolvedTalkId) => {
            if (resolvedTalkId) {
              setConversationId(resolvedTalkId);
            }
          });
        }
        reset();
      }
    });

    async function reset() {
      setIsConnected(false);
      setRealtimeEvents([]);
      setItems([]);
      setMemoryKv({});

      const client = clientRef.current;
      client.disconnect();

      const wavRecorder = wavRecorderRef.current;
      await wavRecorder.end();

      const wavStreamPlayer = wavStreamPlayerRef.current;
      await wavStreamPlayer.interrupt();
    }
  }, [items]); // Add items as a dependency

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === "none" && wavRecorder.getStatus() === "recording") {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === "none" ? null : { type: "server_vad" },
    });
    if (value === "server_vad" && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === "none");
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll("[data-conversation-content]")
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext("2d");
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies("voice")
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              "#0099ff",
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext("2d");
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies("voice")
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              "#009900",
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    client.updateSession({ instructions: instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: "whisper-1" } });

    // Add tools
    client.addTool(
      {
        name: "set_memory",
        description: "Saves important data about the user into memory.",
        parameters: {
          type: "object",
          properties: {
            key: {
              type: "string",
              description:
                "The key of the memory value. Always use lowercase and underscores, no other characters.",
            },
            value: {
              type: "string",
              description: "Value can be anything represented as a string",
            },
          },
          required: ["key", "value"],
        },
      },
      async ({ key, value }: { [key: string]: any }) => {
        setMemoryKv((memoryKv) => {
          const newKv = { ...memoryKv };
          newKv[key] = value;
          return newKv;
        });
        return { ok: true };
      }
    );

    // handle realtime events from client + server for event logging
    client.on("realtime.event", (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on("error", (event: any) => console.error(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    setItems(client.conversation.getItems());

    return () => {
      // disconnectConversation();
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">
      <div className="conversation-wrapper">
        <div className="conversation-container">
          <div className="older-messages">
            {!items.length && (
              <>
                <div>Start making dishes with FiveOut-AI...</div>
                <AudioLines className="m-auto h-full w-20" />
              </>
            )}
            {items.map((conversationItem, i) => (
              <div
                className={`conversation-item ${conversationItem.role}`}
                key={conversationItem.id}
              >
                <div className="speaker-content">
                  {conversationItem.type === "function_call_output" && (
                    <div>{conversationItem.formatted.output}</div>
                  )}
                  {!!conversationItem.formatted.tool && (
                    <div>
                      {conversationItem.formatted.tool.name}(
                      {conversationItem.formatted.tool.arguments})
                    </div>
                  )}
                  {!conversationItem.formatted.tool &&
                    (conversationItem.role === "user" || conversationItem.role === "assistant") && (
                      <div>
                        {conversationItem.formatted.transcript ||
                          conversationItem.formatted.text ||
                          "(truncated)"}
                      </div>
                    )}
                  {conversationItem.formatted.file && (
                    <audio
                      src={conversationItem.formatted.file.url}
                      controls
                      className="audio-player"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="latest-message-container">
            {items.length > 0 && items[items.length - 1].role === 'assistant' && (
              <div className="latest-message">
                {items[items.length - 1].formatted.transcript ||
                  items[items.length - 1].formatted.text ||
                  "(truncated)"}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="controls-container">
        <div className="flex justify-center items-center gap-4">
          {isConnected && (
            <Toggle
              defaultValue={false}
              labels={["manual", "auto"]}
              values={["none", "server_vad"]}
              onChange={(_, value) => changeTurnEndType(value)}
            />
          )}
          {isConnected && canPushToTalk && (
            <button
              className={`text-white p-2 rounded-full ${isRecording ? "bg-red-500 animate-pulse " : "bg-green-500 "}`}
              disabled={!isConnected || !canPushToTalk}
              onMouseDown={startRecording}
              onMouseUp={stopRecording}
            >
              <Mic size={24} />
            </button>
          )}
          <button
            className={`p-1 border-2 flex items-center justify-center gap-2 group rounded-full ${isConnected ? "border-red-500 bg-red-100  text-sm px-2 text-red-500 hover:bg-red-200" : "border-green-500 text-green-500 bg-green-100 font-[500] text-lg px-5  "}`}
            onClick={
              isConnected ? disconnectConversation : connectConversation
            }
          >
            {isConnected ? (
              <X
                size={18}
                className="bg-red-200 rounded-full p-0.5 group-hover:bg-white "
              />
            ) : (
              <Zap fill="lightgreen" />
            )}
            {isConnected ? "end" : "Start"}
          </button>
        </div>
        {canPushToTalk && items.length > 0 && (
          <p className="mt-4 text-gray-500">Hold the Mic to talk</p>
        )}
        {!canPushToTalk && items.length > 0 && (
          <p className="mt-4 text-gray-500">
            In Auto Mode you can talk normally
          </p>
        )}
      </div>
    </div>
  );
}