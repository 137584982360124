import React, { useEffect, useState } from "react";
import { fetchUserReceipts } from "../../../utils/firestoreUtils";
import { auth, getFirebaseImageUrl } from "../../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import ReceiptDashboard from "../../ReceiptDashboard";
import FirestoreDocumentDetails from "../FirestoreDocumentDetails";

export default function PastReceipts() {
  const [receiptDetails, setReceiptDetails] = useState(null);
  const userUid = localStorage.getItem("userUid");

  const [imageUrl, setImageUrl] = useState(null);
  const [renderedReceiptId, setRenderedReceiptId] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    fetchUserReceipts(userUid, "allUserReceipts");
  }, [userUid]);

  useEffect(() => {
    const fetchImageUrl = async () => {
      const url = await getFirebaseImageUrl(receiptDetails?.image_url);
      setImageUrl(url);
    };

    if (receiptDetails) {
      fetchImageUrl();
    }
  }, [userUid]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      
      {user && (
        <FirestoreDocumentDetails
          setRenderedReceiptId={setRenderedReceiptId}
        />
      )}
      {renderedReceiptId && (
        <ReceiptDashboard renderedReceiptId={renderedReceiptId} />
      )}
    
    </div>
  );
}
