import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import RenderReceipts from "./RenderReceipts";
import { fetchUserReceipts } from "../../utils/firestoreUtils";
import { setUniqueReceiptData } from "../../utils/dataStats";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/config";

const FirestoreDocumentDetails = ({ setRenderedReceiptId }) => {
  const [documentDetails, setDocumentDetails] = useState({});
  const [datesOfReceiptsUploaded, setDatesOfReceiptsUploaded] = useState([]);
  const [datesOfReceipts, setDatesOfReceipts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(() => new Date());
  const userUid = localStorage.getItem("userUid");
  const [user] = useAuthState(auth);
  const fetchData = async () => {
    try {
      const details = await fetchUserReceipts(userUid, "allUserReceipts");
      setDocumentDetails(details);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (documentDetails && (Array.isArray(documentDetails) || Object.keys(documentDetails).length > 0)) {
      setUniqueReceiptData(documentDetails, setDatesOfReceiptsUploaded, setDatesOfReceipts);
    }
  }, [documentDetails]);


  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div >
      {documentDetails && Object.keys(documentDetails).length === 0 ? (
        <p className="text-center">You have no data so far...</p>
      ) : (
        <div>
          <Calendar
            setSelectedDate={setSelectedDate}
            datesOfReceiptsUploaded={datesOfReceiptsUploaded}
            datesOfReceipts={datesOfReceipts}
          />

          {selectedDate && (
            <RenderReceipts
              selectedDate={selectedDate}
              setRenderedReceiptId={setRenderedReceiptId}
              user={user}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FirestoreDocumentDetails;