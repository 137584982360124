import * as React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { Avatar, AvatarImage, AvatarFallback } from "../@/components/avatar";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../@/components/dropdown-menu";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/config";
import DashboardSidebar from "../components/track-foods/dashboard/DashboardSidebar";
import DashboardMobileNavbar from "../components/track-foods/dashboard/DashboardMobileNavbar";
import { ChefHat } from "lucide-react";

export function DashboardPage() {
  return (
    <div className="flex h-screen w-full flex-col bg-muted/40 overflow-hidden">
      <DashboardSidebar />
      <DashboardMobileNavbar />
      <div className="flex flex-col sm:gap-4 bg-[#f4f2ee] sm:py-4 sm:pl-14 flex-grow overflow-auto">
        <header className="sticky top-0 z-[9999] flex h-14 items-center gap-4 justify-between border-b bg-white px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
          <h2 className="text-2xl font-semibold flex items-center gap-2">
            <ChefHat className="w-6 h-6" />
            Fiveout
          </h2>
          <UserImageAvatar />
        </header>

        <main className="p-4 flex-grow overflow-auto sm:px-6 sm:py-0">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export function UserImageAvatar() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [userImage, setUserImage] = React.useState(
    "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png"
  );
  React.useEffect(() => {
    if (user && user.photoURL) {
      setUserImage(user.photoURL);
      console.log(user.photoURL);
    }
  }, [user]);

  const handleLogout = () => {
    localStorage.removeItem("email");
    auth.signOut().then(() => {
      navigate("/", { replace: true });
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className="w-8 h-8">
          <AvatarImage
            src={
              userImage ||
              "https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png"
            }
            alt="user image"
          />
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuSeparator />
        <DropdownMenuItem onSelect={handleLogout}>Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
