import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsNdConditions from "./pages/TermsNdConditions";
import Navbar from "./components/common/Navbar";
import { ResultTrackFoods } from "./pages/ResultTrackFoods";
import Login from "./pages/Login";
import NotFound from "./components/common/NotFound";
import HomeNew from "./pages/HomeNew";
import Roadmap from "./pages/Roadmap";
import GroceryList from "./pages/GroceryList";
import { DashboardPage } from "./pages/DashboardPage";
import Dashboard from "./components/track-foods/dashboard/Dashboard";
import PastReceipts from "./components/track-foods/dashboard/PastReceipts";
import CookWithMe from "./pages/CookWithMe";
export const routes = [
  {
    path: "/",
    element: <Navbar />,
    children: [
      {
        index: true,
        element: <HomeNew />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsNdConditions />,
      },      
      {
        path: "/roadmap",
        element: <Roadmap />,
      },
      {
        path: "/track-food/result/:key",
        element: <ResultTrackFoods />,
      },
      {
        path: "/login",
        element: <Login />,
      },

      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "past-receipts",
        element: <PastReceipts />,
      },
      {
        path: "grocery-list",
        element: <GroceryList />,
      },
      {
        path: "cook-with-me",
        element: <CookWithMe />,
      },
    ],
  },
];
