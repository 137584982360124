import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { TrendingUp } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../@/components/card";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../firebase/config";

const ExpenseChart = () => {
  const [processedDataForPlotting, setProcessedDataForPlotting] = useState([]);
  const userUid = localStorage.getItem("userUid");

  useEffect(() => {
    const fetchDataForPlotting = async () => {
      try {
        const allUserReceiptsCollectionRef = collection(db, "allUserReceipts");
        const userRef = doc(db, "users", userUid);
        const q = query(
          allUserReceiptsCollectionRef,
          where("userRef", "==", userRef)
        );
        const querySnapshot = await getDocs(q);
        const documents = querySnapshot.docs;

        const newDataForPlotting = documents.map((doc) => {
          const requestData = doc.data();
          return {
            date: requestData?.processed_data?.datetime ?? "",
            amount: parseFloat(
              requestData?.processed_data?.total_amount ?? "0"
            ),
            supermarket: requestData?.processed_data?.store_name ?? "Unknown",
          };
        });

        // Sort the data by date
        newDataForPlotting.sort((a, b) => new Date(a.date) - new Date(b.date));

        // Group by date and sum amounts
        const groupedData = newDataForPlotting.reduce((acc, curr) => {
          const dateKey = curr.date.split("T")[0]; // Get just the date part
          if (!acc[dateKey]) {
            acc[dateKey] = { date: dateKey, amount: 0 };
          }
          acc[dateKey].amount += curr.amount;
          return acc;
        }, {});

        const finalData = Object.values(groupedData);
        setProcessedDataForPlotting(finalData);
      } catch (error) {
        console.error("Error fetching data for plotting:", error);
      }
    };

    fetchDataForPlotting();
  }, [userUid]);

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  };

  const formatTooltip = (value, name, props) => {
    return [`€${value.toFixed(2)}`, "Total Amount"];
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Expense Chart</CardTitle>
        <CardDescription>Last 4 weeks</CardDescription>
      </CardHeader>
      <CardContent className="p-1 sm:p-4 ">
        {processedDataForPlotting && processedDataForPlotting.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={processedDataForPlotting}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={formatXAxis}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => `€${value}`}
              />
              <Tooltip formatter={formatTooltip} />
              <Bar dataKey="amount" fill="orange" radius={4} />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div>No data available</div>
        )}
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          {/* You might want to calculate this percentage based on the data */}
          Trending up by X% this month <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing total expenses for the last 4 weeks
        </div>
      </CardFooter>
    </Card>
  );
};

export default ExpenseChart;
