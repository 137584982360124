import {
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { auth, provider } from "./config";
import { ensureUserDocument } from './userUtils';

const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then(async (data) => {
      localStorage.setItem("email", data.user.email);
      localStorage.setItem("userUid", data.user.uid);
      
      // Check if user is admin
      const adminEmails = require("../config").adminEmails;
      const isAdmin = adminEmails && adminEmails.includes(data.user.email);
      localStorage.setItem("isAdmin", isAdmin);
      console.log("Ensuring user document", data.user);

      try {
        await ensureUserDocument(data.user);
        console.log("User document ensured");
        window.location.href = `/dashboard`;
      } catch (error) {
        console.error("Error ensuring user document:", error);
      }
    })
    .catch((err) => console.log("Error during Google sign-in:", err));
};

const sendPasswordlessSignInLink = (email, setMessage) => {
  const url = process.env.NODE_ENV === "production"
    ? "https://www.5outapp.com/dashboard"
    : "http://localhost:3000/dashboard";

  const actionCodeSettings = {
    url: url,
    handleCodeInApp: true,
  };

  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      localStorage.setItem("emailForSignIn", email);
      setMessage("Please check your email to sign in.");
    })
    .catch((error) => {
      console.error("Error sending sign-in link:", error);
      setMessage("Error sending sign-in link. Please try again.");
    });
};

const completeSignInWithEmailLink = async (email) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    try {
      const result = await signInWithEmailLink(auth, email, window.location.href);
      localStorage.removeItem("emailForSignIn");
      await ensureUserDocument(result.user);
      console.log("User document ensured after email link sign-in");
      window.location.href = `/dashboard`;
    } catch (error) {
      console.error("Error completing sign-in:", error);
    }
  }
};

export {
  signInWithGoogle,
  sendPasswordlessSignInLink,
  completeSignInWithEmailLink,
};
